<template>
  <Modal closePosition="hidden">
    <div class="root p-6">
      <h2 class="font-h font-semibold text-2xl text-primary">
        Please subscribe to a plan
      </h2>

      <div class="mt-2.5 text-sm text-primary">
        Transcript access is limited on free plan
      </div>

      <div class="mt-4 flex items-center justify-end">
        <Button size="small" variant="secondary" :onClick="closeModal" classname="mr-2.5">
          Okay
        </Button>
        <Button size="small" :onClick="showBilling" classname="pr-1.5">
          Check Plans <ChevronRight classname="h-3 w-3 ml-0.75"/>
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'
import ChevronRight from '@/components/base/icons/ChevronRight.vue'

export default {
  components: {
    Modal,
    Button,
    ChevronRight
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    }),
    showBilling: function() {
      this.closeModal()
      this.openModal({ name: 'AccountInfo', props: { tab: 'Plans' } })
    }
  }
}
</script>
<style lang="less" scoped>
.root {
  width: 420px;
}
</style>
